@tailwind base;
@tailwind components;
@tailwind utilities;

.img-login {
    width: 100%;
    /* margin-left: -2%; */
}
.preview {
    height: 100px;
    width: 100px !important;
}
.errors {
    color: red;
}
.form-login input {
   height: 48px;
   border-radius: 12px;
   border: 1px solid #C5C6CC;
   padding: 12px 16px 12px 16px;
   gap: 8px;
   width: 640px !important;
}
.label-login {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}
.btn-login {
    width: 180px !important;
    height: 50px;
    padding: 13px !important;
    border-radius: 12px !important;
    gap: 8px;
    display: flex !important;
    justify-content: center;
    justify-items: center;
    background: #EFA23A !important;
    margin-top: 2% !important;
    font-size: 14px !important;
}
.btn-login img {
    margin-top: 0px;
}
.form-head {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.form-head img {
    width: 516px;
    height: 192px;
}
.title-form-label {
    font-family: Inter;
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-align: center;

}
.form-right {
    margin-top: 14% !important;
}
.card {
    margin-bottom: 2%;
}
.card-title {
    font-size: 18px;
    font-weight: 900;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;

}
.required-label {
    font-weight: 300;
}
.form-check-label {
    margin-top: 2%;
    margin-left: 2%;
    color: #000;
}
.form-radio {
    height: 40px;
    padding: 10px;
    margin-top: 4px;
    border-radius: 7px;
    border: 1px solid #eee;
    width: 100% !important;
}
.form-check-input.is-valid ~ .form-check-label {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0px !important;
    margin-top: 1%;
}
.pt {
    margin-top: 9%;
}
.pt-s {
    margin-top: 5%;
}
.form-control[type="file"] {
    overflow: hidden;
    display: none;
}
.file-btn {
    width: 87px !important;
    height: 28px !important;
    padding: 4px 10px 4px 10px !important;
    border-radius: 32px !important;
    border: 1px soldi #eee !important;
    color: #000 !important;
    background: transparent !important;
    margin-left: 69%;
    font-weight: bold !important;
    margin-top: 2px;
}
.custom-input-file {
    width: 409px;
    border: 1px solid #E7E9EB; 
    padding: 2px;
    max-height: 40px;
}
.custom-input-file label {
    padding-top: 3%;
}
.form-add {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #EFA23A;
    cursor: pointer;
}
.form-left {
    margin-left: -1%;
    margin-top: 1%;
}
.form-membre {
    height: 49px !important;
    width: 129px !important;
    padding: 16px 32px 16px 32px !important;
    border-radius: 12px;
    margin-left: 6%;
}

.btn-cancel {
    width: 118px !important;
    height: 49px !important;
    padding: 16px 32px 16px 32px !important;
    border-radius: 12px !important;
    border: 1.5px solid #EFA23A !important;
    background: #fff !important;
    color: #EFA23A !important;
    margin-top: 6%;
    margin-right: 4%;

}

.invalid-feedback {
    font-size: 12px;
    margin-left: 3px !important;
}
.error {
    border: 1px solid red !important;
}
.d-block {
    font-size: 12px;
    /* color: red; */
    margin-left: 3px;
    margin-top: 3px !important;
}

.candidat {
    margin-left: -1.5%;
    margin-bottom: 1%;
}
#status {
    margin-bottom: 2%;
}
.photo_webcam {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.1);
    top: 0;
    z-index: 57;
}
 .form-select {
    padding: 11px 16px !important;
}

.check {
    background-color: #0284fe;
    width: 14px;
    height: 14px;
    outline: 1px solid #0284fe;
    outline-offset: 1px;
    box-shadow: none;
}
.file-input {
    width: 459px !important;
}
.btn-recompense {
    color: #fff;
    text-decoration: none;
    height: 40px;
    padding: 12px !important;

}
.f-ri {
    float: right;
}